import React, { useEffect, useRef } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import ApplicationRoutes from './Components/Routes/ApplicationRoutes';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import { useDispatch } from 'react-redux';
import { authActions } from './redux/reducers/auth';
import Snackbar from './Components/Providers/Snackbar';
import { useUserActivityTracking } from './globalHooks';

function App() {
  const dispatch = useDispatch();
  const { updateVisibility } = useUserActivityTracking();
  const isReloadingRef = useRef(false);

  const token = localStorage.getItem('auth');

  useEffect(() => {
    if (token) {
      dispatch(authActions.loginHandler({ token, loggedIn: true }));
    }
  }, [token]);

  // Visibility tracking
  useEffect(() => {
    const visibilityHandler = (state) => {
      const date = new Date().toISOString();
      switch (state) {
        case 'visible':
          updateVisibility(true, date);
          isReloadingRef.current = false; // Reset the reloading flag when the page is visible
          break;
        case 'hidden':
          updateVisibility(false, date);

          if (isReloadingRef.current) {
            console.info('This page is reloaded, not stopping the timer.');
          } else {
            console.info('This page is hidden, stopping the timer.');
          }
          break;
        default:
          console.error({
            message:
              "Invalid params (state) provided, allowed values can be 'visible' or 'hidden'",
            state,
          });
          throw Error('Error in params');
      }
    };

    const handleVisibilityChange = () => {
      visibilityHandler(document.visibilityState);
    };

    // Listen to beforeunload event to set the reloading flag
    const handleBeforeUnload = (event) => {
      isReloadingRef.current = true;
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [updateVisibility]);

  return (
    <Snackbar>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ApplicationRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </Snackbar>
  );
}

export default App;
